import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import getConfig from 'next/config'




export const getUserSettings = createAsyncThunk(
  'settings/getUserSettings',
  async (_, {getState}) => {
    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;

    let token;
    if ('token' in user) {  token = user.token } 
    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.settings.data
      }

      // send axios request
      const response = await axios.get(
        `${backendUrl}/api/user/get-user-settings/`,
        config
      )

      return response.data
    }
  }
)


export const updateLanguage = createAsyncThunk (
  'settings/updateLanguage',
  async (formData:{language:string}, {getState}) => {

    //get state to access token
    const userState:any = getState();
    const {user} = userState.user.data;

    let token;
    if ('token' in user) {  token = user.token } 
    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}

      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return userState.settings.data
      }

      // send axios request
      const response = await axios.post(
        `${backendUrl}/api/user/update-user-language/`,
        formData,
        config
      )
      return response.data
    }
  }
)


export interface SettingsDataProps {
  user: number;
  language:string;
}

export interface SettingsStateProps {
  data: SettingsDataProps;
  status: string;
  error: string | null;
}

const initialData:SettingsDataProps = {
  user:0,
  language:'jp'
}

const initialState: SettingsStateProps = {
  data:initialData,
  status: 'idle',
  error:'null'
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.data = {...state.data, language:action.payload}
    },

    resetSettings: (state) => {
      state.data = initialData
    }

  },
  extraReducers: (builder) => {
    // getUserSettings
    builder.addCase(getUserSettings.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getUserSettings.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(getUserSettings.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    // updateLanguage
    builder.addCase(updateLanguage.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(updateLanguage.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

  },
});

//reducers
export const {setLanguage} = settingsSlice.actions;


export default settingsSlice.reducer;
