import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

//Fonts
import { Raleway } from 'next/font/google';
import localFont from 'next/font/local'

//SEO header
import SEOHead from '@/utils/SEOHead';


//redux & redux-persist
import {Provider} from 'react-redux';
import store, {persistor} from '@/store/store';
import { PersistGate } from 'redux-persist/integration/react';

//route loader
import RouteLoader from '@/src/atoms-new/loader/RouteLoader';

//css
import '../styles/global.css'


const raleway = Raleway({
  subsets: ['latin'],
  variable: '--font-raleway',
})

const knewave = localFont({
  src: [
    {
      path: '../assets/fonts/Knewave/Knewave-Regular.ttf',
      weight: '400'
    },
  ],
  variable: '--font-knewave'
})

const futura = localFont({
  src: [
    {
      path: '../assets/fonts/Futura/futura medium bt.ttf',
      weight: '400'
    },
  ],
  variable: '--font-futura'
})
const futuraBold = localFont({
  src: [
    {
      path: '../assets/fonts/Futura/unicode.futurab.ttf',
      weight: '400'
    },
  ],
  variable: '--font-futura-bold'
})



function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());


  return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <main className={` ${raleway.variable} ${knewave.variable} ${futura.variable} ${futuraBold.variable} bg-white`}>
              <SEOHead />

              <RouteLoader />

              <Component {...pageProps} />
            </main>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
  )
}

export default MyApp
