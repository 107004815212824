import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


import { loadLessonPage, patchLesson, deleteLesson } from './lessonSlice';



export const updateInstructorNotificationLastChecked = createAsyncThunk(
  'user/updateInstructorNotificationLastChecked',
  async (_, {getState}) => {

    //get state to access token and current notif last checked
    const userState:any = getState();
    const {user} = userState.user.data;
    const {profile} = userState.instructor.data;
    let token;
    if ('token' in user) {  token = user.token } 
    //check for token in current state first
    if (token) {
      const {notification_last_checked} = profile
      const currentDate = new Date();
      if (notification_last_checked) {
        const lastNotification = new Date(notification_last_checked)
        // update notification
        if (lastNotification < currentDate) {
          //create config header object
          const config = {
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
          //get env var
          const res = await fetch('/api/env/backend-url')
          const {backendUrl} = await res.json();
          if (!backendUrl) {
            console.error('Backend URL env is missing');
            return userState.instructor.data.profile
          }

          //make patch to update read
          const response = await axios.patch(
            `${backendUrl}/api/instructor/update-instructor-profile/`,
            {notification_last_checked: currentDate},
            config
          )
          return response.data
        }
      }
    }
    return userState.instructor.data.profile
  }
)





/**
 * INTERFACE FOR DATA
 */
interface InstructorProfileProps {
  instructorName: string;
}

export interface LessonPageProps {
  lessonId: string;
  title: string;
  genre:string;
  targetAudience: string;
  description:string;
  instructor_id: string;
  instructorName: string;
  instructorDescription:string;
  price: number | null;
  auto_book: boolean;
  published: boolean;
  created_at: Date | null;
  nextClassId: string;
  lastClassId: string;
  upcoming_classes: any[];
}




/**
 * INITIAL DATA
 */
export const blankInstructor = {
  instructorId:'', 
  instructorName:'',
}
export const blankLessonPage = {
  lessonId: '',
  title:'', genre:'', targetAudience:'', description:'', 
  instructor_id:'', instructorName:'', instructorDescription:'',
  price:null, auto_book:false,
  published:false, created_at:null,
  nextClassId:'', lastClassId:'',
  upcoming_classes:[]
}


/**
 * DATA INTERFACE SETUP
 */
// instructorSlice data interface
interface InstructorDataProps {
  profile: InstructorProfileProps | null;
  lessonPage: LessonPageProps | null
}

// whole redux interface with status & error
export interface InstructorStateProps {
  data: {
    profile: InstructorProfileProps | null,
    lessonPage: LessonPageProps | null;
  }
  status: string;
  error: string | null;
}

//instructorSlice data initial data
const initialData:InstructorDataProps = {
  profile: blankInstructor,
  lessonPage: blankLessonPage
}

//whole redux initial state with status & error
const initialState: InstructorStateProps = {
  data:initialData,
  status: 'idle',
  error:'null'
};


export const instructorSlice = createSlice({
  name: 'instructor',
  initialState: initialState,
  reducers: {
    instructorLogin: (state, action) => {
      state.data = {profile: action.payload, lessonPage: blankLessonPage}
    },

    // updateInstructorProfile: (state, action) => {
    //   state.data = {profile: action.payload, lessonPage: blankLessonPage}
    // },
    updateInstructorProfile: (state, action) => {
      state.data = {...state.data, profile:action.payload}
    },

    resetInstructorSlice: (state) => {
      state.data = initialData
    },
    resetLessonPage: (state) => {
      state.data = {...state.data, lessonPage:blankLessonPage}
    },
  },
  extraReducers: (builder) => {
    /* -------------- INSTRUCTOR SLICE (PROFILE) ---------------*/
    //updateInstructorNotificationLastChecked
    builder.addCase(updateInstructorNotificationLastChecked.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateInstructorNotificationLastChecked.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, profile:action.payload}
    })
    builder.addCase(updateInstructorNotificationLastChecked.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    /* -------------- LESSON SLICE ---------------*/
    //loadLessonPage
    builder.addCase(loadLessonPage.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(loadLessonPage.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(loadLessonPage.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    //patchLesson
    builder.addCase(patchLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(patchLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(patchLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    //deleteLesson
    builder.addCase(deleteLesson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteLesson.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = {...state.data, lessonPage:action.payload}
    })
    builder.addCase(deleteLesson.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

  },
});

//reducers
export const {instructorLogin, updateInstructorProfile, resetInstructorSlice, resetLessonPage} = instructorSlice.actions;


//export const selectUser = (state) = state.user
export default instructorSlice.reducer;
